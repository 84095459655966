.combo-omni {
    background-color: var(--light-hover) !important;
}

    .combo-omni:after {
        background-image: linear-gradient( 0deg, var(--primary) 0%, var(--primary) 50%, transparent 50%, transparent 100% ) !important;
        border-bottom: 2px solid var(--info) var(--primary) !important;
    }

div[aria-selected="true"] {
    background-color: var(--primary);
    color: white;
}

    div[aria-selected="true"]:hover {
        background-color: var(--primary);
        color: white;
    }
.combo-omni-small  {
    min-width:initial !important;
}
.combo-omni-small > input{
    width:120px;
}
.listBox-combo {
    max-height: 250px !important;
}
    .listBox-combo > div:not(.option-disabled):hover {
        background-color: var(--primary) !important;
        color: white !important;
    }

.searchOption {
    color: var(--primary);
}

.listBox-combo > div:not(.option-disabled):hover .searchOption {
    color: white !important;
}
.option-disabled {
    background-color: transparent !important;
    cursor: not-allowed !important;
}
    .option-disabled :hover {
        background-color: transparent !important;
    }

.option-enabled :hover{
    background-color: var(--primary) !important;
    color: white !important;
}