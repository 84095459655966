.textBox-omni {
    background-color: var(--light-hover) !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-radius: 2px !important;
}
.textBox-omni > input {
    width: 100%;
}

    .textBox-omni:after {
        background-image: linear-gradient( 0deg, var(--primary) 0%, var(--primary) 50%, transparent 50%, transparent 100% ) !important;
        border-bottom: 2px solid var(--info) var(--primary) !important;
    }
.uppercaseInput > input{
    text-transform: uppercase !important;
}


.maskedText-omni{
    background-color: var(--light-hover) !important;
    border-bottom: 1px solid var(--dark) !important;
    margin-right: 10px;
    outline: none !important;
}

.maskedText-omni> .ms-TextField-wrapper >  
.ms-TextField-fieldGroup> .ms-TextField-field:focus{
    outline: none !important;
    border-top: none;
    border-bottom: 2px solid var(--info) var(--primary) !important;
}

.maskedText-omni> .ms-TextField-wrapper >.ms-TextField-fieldGroup{
    border: none !important;
    background-color: transparent;
    outline: none !important;
}

.maskedText-omni:focus-visible{
    outline: none !important;
}

.maskedText-omni >.ms-TextField-wrapper:focus-visible{
    outline: none !important;
}

.maskedText-omni> .ms-TextField-wrapper >.ms-TextField-fieldGroup:focus-visible {
    outline: none !important;
}

.ms-TextField-fieldGroup::after{
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: 2px solid var(--primary) !important;
}

@media (max-width:992px) {
    .textBox-omni{
        width: 100% !important;
        margin-right: -100px;
    }
}