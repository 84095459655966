.item-acronym-table {
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-acronym-table > div > span {
    color: #fff;
    background-color: var(--orange);
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    padding-top: 4px;
    text-transform: uppercase;
}
.detail-table {
    background-color: #FFF !important;
    margin-top: 14px;
}
.detail-table-header-title > div {
    color: var(--dark);
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail-table-cell > div {
/*   color: var(--dark);*/
    font-family: Segoe UI;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail-table-header-title-left > div {
    color: var(--dark);
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
}
.detail-table-body-description > div {
    color: var(--dark);
    font-family: Segoe UI;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 35px;
    text-transform: uppercase;
}
.detail-table-body-image > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.first-item > div {
    justify-content: normal;
    padding-left: 35px;
}
.detail-table-scroll {
    overflow-y: auto;
    height: auto;
}
.detail-table-scroll-complete {
    overflow-y: auto;
    height: 75vh;
}

.header-iconEdit {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
}
.color-info {
    color: var(--info);
}
.color-danger {
    color: var(--danger);
}
.color-green {
    color: var(--green);
}
.item-header {
    background-color: var(--light-hover) !important;
    color: var(--info) !important;
}