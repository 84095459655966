button[aria-disabled="true"] {
    cursor: not-allowed !important;
}

.barButtons-container{
    display: flex;
    width: 100%;
}

.barButtons {
    border-bottom: 1px solid var(--primary);
}
.border-gray {
    border-bottom: 1px solid var(--light);
}
.background-color-light-hover * {
    background-color: var(--light-hover);
}
.background-color-light-hover:hover * {
    background-color: var(--light-hover);
}
.home-header-text-button {
    padding-left: 18px;
}
.contract-right {
    border-right: var(--secondary) solid 1px;
    padding-right: 15px;
}
.contract-left {
    border-left: var(--secondary) solid 1px;
    padding-left: 15px;
}