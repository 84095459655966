.name-menu {
    background-color: var(--light-hover);
    padding: 10px;
}

.color-info {
    color: var(--info) !important;
}
.color-info-fill {
    fill: var(--info) !important;
}
.color-purple-fill {
    fill: var(--purple) !important;
}
.color-danger-fill {
    fill: var(--danger) !important;
}
.color-secondary-fill {
    fill: var(--secondary) !important;
}
.color-secondary {
    color: var(--secondary) !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.cursor-pointer > label{
    cursor: pointer !important;
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}
.cursor-not-allowed > label {
    cursor: not-allowed !important;
}
.ul-list {
    list-style: none;
    margin:5px
}
/*  --------------------        panel         -------------------*/
.drawer-footer-container {
    width: inherit;
    text-align: right !important;
}
/*  --------------------        tile-button         -------------------*/


.tile-button-panel {
    justify-content: space-between !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    width: 124px;
    border: none !important;
    background-color: #898989 !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px !important;
    max-height: 60px;
}

    .tile-button-panel > span:first-child {
        color: #fff;
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }

    .tile-button-panel:hover {
        background: #898989d6 !important;
    }

    .tile-button-panel > span:first-child {
        padding-top: 3px;
    }

.tile-button-panel-primary {
    background-color: #0079D8 !important;
}

    .tile-button-panel-primary:hover {
        background-color: #0079d8d3 !important;
    }

/*  --------------------        panel         -------------------*/
.overflow-panel {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 350px);
}
/*  --------------------        checkbox         -------------------*/
.padding-checkbox {
    padding-bottom: 5px;
    fill: var(--info);
}
.margin-checkbox-img {
    margin-right: 10px;
}
/*  --------------------        table         -------------------*/
.updater-table-container {
    margin: 12px 12px 55px 12px;
    max-width: inherit;
    overflow-x: scroll;
    background-color: #fff;
}

.table-updater {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    table-layout: auto !important;
    --bs-table-bg: transparent !important;
}

    .table-updater > thead > tr > th {
        font-weight: 600;
    }

    .table-updater > tbody > tr {
        cursor: pointer;
    }

        .table-updater > tbody > tr.item-table-selected {
            background-color: var(--primary);
            color: #fff;
        }

            .table-updater > tbody > tr.item-table-selected > td {
                color: #fff;
            }

                .table-updater > tbody > tr.item-table-selected > td > svg g path {
                    fill: #fff;
                }

            .table-updater > tbody > tr.item-table-selected:hover {
                opacity: 0.8;
            }

/*  --------------------        popover         -------------------*/
.popover {
    height: 44px !important;
    padding-bottom: 40px !important;
    padding-top: 5px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    background-color: var(--light-hover) !important;
    cursor: pointer !important;
}
.popover > label {
    cursor: pointer !important;
}
    .popover:hover {
        background-color: #E3E3E3 !important;
    }

.BtnTrigger {
    border: none;
    border-radius: 4px; 
    height: 32px;
    margin-top: 6px;
    margin-left: 6px;
}
 
    .BtnTrigger * {
        background-color: var(--primary) !important;
        color: #fff;
    }
    .BtnTrigger > span {
        border-radius: 4px;
        padding-left: 6px;
        padding-right: 6px;
    }